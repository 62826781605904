<template>
  <v-dialog max-width="600" :value="value" @input="$emit('input')">
    <v-card max-width="600">
      <v-card-title>條款及細則</v-card-title>
      <v-card-text>
        <div style="font-weight: bold; text-decoration: underline">登記：</div>
        <ul>
          <li>您必須在註冊過程中提供準確且完整的資訊。</li>
          <li>註冊不可轉讓。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">行為守則：</div>
        <ul>
          <li>參與者應以尊重其他參與者、組織者和工作人員的方式行事。</li>
          <li>絕不容忍任何形式的騷擾或破壞性行為。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">責任：</div>
        <ul>
          <li>
            活動主辦單位對活動期間可能發生的任何損失、損壞、傷害或任何其他不可預見的事件不承擔任何責任。
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">攝影和攝影：</div>
        <ul>
          <li>參與即表示您同意為宣傳目的而被拍照或錄音。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">活動取消：</div>
        <ul>
          <li>主辦單位可能會自行決定取消或重新安排活動。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">健康與安全：</div>
        <ul>
          <li>參與者應對活動期間自身的健康和安全負責。</li>
          <li>任何健康狀況或過敏情況必須事先告知組織者。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">遵守：</div>
        <ul>
          <li>參與者必須遵守活動組織者所製定的所有規則和規定。</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">
          條款與條件的變更：
        </div>
        <ul>
          <li>活動組織者保留隨時修改這些條款和條件的權利。</li>
        </ul>
        <div class="mt-2" style="font-weight: bold">
          透過確認您的參與，您承認您已閱讀並同意遵守這些條款和條件。
        </div>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-btn class="mx-auto" text @click="$emit('input')">關閉</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TacDialog",
  props: {
    value: Boolean,
  },
};
</script>
