import Vue from "vue";
import VueRouter from "vue-router";
import Booking from "../components/Booking.vue";
import Welcome from "../components/Welcome.vue";
import EditBooking from "../components/EditBooking.vue";
Vue.use(VueRouter);

const routes = [
    {
        path: "/booking",
        name: "Booking",
        component: Booking,
    },
    {
        path: "/",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/editBooking/:id",
        name: "EditBooking",
        component: EditBooking,
    }

];
const router = new VueRouter({
    mode: "history",
    routes,
});


export default router;
