<template>
  <v-dialog max-width="600" :value="value" @input="$emit('input')">
    <v-card max-width="600">
      <v-card-title>Terms and Conditions</v-card-title>
      <v-card-text>
        <div style="font-weight: bold; text-decoration: underline">Registration:</div>
        <ul>
          <li>
            You must provide accurate and complete information during the registration
            process.
          </li>
          <li>Registration is non-transferable.</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">Code of Conduct:</div>
        <ul>
          <li>
            Participants are expected to conduct themselves in a respectful manner towards
            other participants, organizers, and staff.
          </li>
          <li>Any form of harassment or disruptive behavior will not be tolerated.</li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">Liability:</div>
        <ul>
          <li>
            The event organizers are not responsible for any loss, damage, injury, or any
            other unforeseen incident that may occur during the event.
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">
          Photography and Videography:
        </div>
        <ul>
          <li>
            By participating, you consent to being photographed or recorded for
            promotional purposes.
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">Cancellation:</div>
        <ul>
          <li>
            The event may be subject to cancellation or rescheduling at the discretion of
            the organizers.
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">
          Health and Safety:
        </div>
        <ul>
          <li>
            Participants are responsible for their own health and safety during the event.
          </li>
          <li>
            Any health conditions or allergies must be communicated to the organizers in
            advance.
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">Compliance:</div>
        <ul>
          <li>
            Participants must comply with all rules and regulations set forth by the event
            organizers.
          </li>
        </ul>
        <div style="font-weight: bold; text-decoration: underline">
          Changes to Terms and Conditions:
        </div>
        <ul>
          <li>
            The event organizers reserve the right to modify these terms and conditions at
            any time.
          </li>
        </ul>
        <div class="mt-2" style="font-weight: bold">
          By confirming your participation, you acknowledge that you have read and agree
          to abide by these terms and conditions.
        </div>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-btn class="mx-auto" text @click="$emit('input')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TacDialog",
  props: {
    value: Boolean,
  },
};
</script>
