<template>
  <div style="height: 100%; width: 100%" class="d-flex pa-2">
    <v-overlay style="z-index: 9999" :value="isLoading">
      <v-progress-circular indeterminate color="#4E342E"></v-progress-circular>
    </v-overlay>
    <v-dialog max-width="400" persistent v-model="dialog.edit">
      <v-card>
        <v-card-title>更改預約 Edit Booking</v-card-title>
        <v-card-text>
          <v-tabs-items touchless style="height: 100%" v-model="step">
            <v-tab-item style="padding: 1px" :value="0">
              <div>請選擇時段 Please select sesion:</div>
              <div class="d-flex mt-4 flex-column">
                <v-card @click="() => onSessionCardClick(1)">
                  <div style="background-color: #e05d28; width: 100%; height: 6px"></div>
                  <div class="d-flex">
                    <div class="pa-4">
                      <div style="font-size: 18px; font-weight: bold; color: #424242">
                        第一節 Session One
                      </div>
                      <div style="font-size: 12px; color: #757575">
                        18 Oct 2024 - 20 Oct 2024
                      </div>
                    </div>
                    <v-checkbox
                      class="my-auto ml-auto"
                      hide-details
                      style="pointer-events: none; user-select: none"
                      @click.stop
                      color="red-darken-3"
                      :value="form.session === 1"
                    ></v-checkbox>
                  </div>
                </v-card>
                <v-card @click="() => onSessionCardClick(2)" class="mt-4">
                  <div style="background-color: #e05d28; width: 100%; height: 6px"></div>
                  <div class="d-flex">
                    <div class="pa-4">
                      <div style="font-size: 18px; font-weight: bold; color: #424242">
                        第二節 Session Two
                      </div>
                      <div style="font-size: 12px; color: #757575">
                        25 Oct 2024 - 27 Oct 2024
                      </div>
                    </div>
                    <v-checkbox
                      class="my-auto ml-auto"
                      hide-details
                      style="pointer-events: none; user-select: none"
                      @click.stop
                      color="red-darken-3"
                      :value="form.session === 2"
                    ></v-checkbox>
                  </div>
                </v-card>
              </div>
            </v-tab-item>
            <v-tab-item style="padding: 1px" :value="1">
              <v-select
                class="mt-4"
                label="人數 Person(s)"
                dense
                outlined
                :items="[
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                ]"
                v-model="form.count"
                @input="() => (form.time = null)"
                persistent-hint
                hint="最多兩位參加者 Maximum 2 participants"
              ></v-select>
              <v-select
                label="請選擇日期 Please select date"
                class="mt-2"
                v-model="form.date"
                @input="onDateSelect"
                dense
                outlined
                hide-details
                :items="selectDateList"
              ></v-select>
              <div v-if="timeslotOptions.length != 0" class="mt-4">
                可選擇時段 Available timeslot
              </div>
              <div class="d-flex flex-wrap">
                <v-card
                  @click="() => onTimeSelect(item)"
                  :style="{
                    overflow: 'hidden',
                    pointerEvents:
                      item.count * 1 + form.count * 1 > 20 ? 'none !important' : 'auto',
                    userSelect:
                      item.count * 1 + form.count * 1 > 20 ? 'none !important' : 'auto',
                    width: '155px',
                  }"
                  class="mr-2 mt-2"
                  v-for="(item, i) in timeslotOptions"
                  :key="i"
                >
                  <v-overlay
                    opacity="0.8"
                    style="color: #fafafa"
                    absolute
                    :value="item.count * 1 + form.count * 1 > 20"
                    >已滿 FULL</v-overlay
                  >
                  <div style="background-color: #e05d28; width: 100%; height: 4px"></div>
                  <div class="d-flex">
                    <div class="pa-2">
                      <div style="font-size: 11px; color: #757575">
                        {{ moment(item.date).format("YYYY-MM-DD") }}
                      </div>
                      <div style="font-size: 14px; font-weight: bold; color: #424242">
                        {{ item.timeslot_time_text }}
                      </div>
                    </div>
                    <v-checkbox
                      class="ml-1 my-auto"
                      hide-details
                      style="pointer-events: none; user-select: none"
                      @click.stop
                      color="red-darken-3"
                      :value="form.time === item.timeslot_time_id"
                    ></v-checkbox>
                  </div>
                </v-card>
              </div>
            </v-tab-item>
            <v-tab-item style="padding: 1px" :value="2">
              <div class="d-flex mt-4 flex-column">
                <div>請確認更改資料</div>
                <div class="d-flex mt-4">
                  <div>日期 Date:</div>
                  <div class="ml-auto">
                    {{ moment(booking.date).format("DD MMM YYYY") }}{{ " -> "
                    }}{{ form.dateText }}
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div>時間 Time:</div>
                  <div class="ml-auto">
                    {{ booking.timeslot_time_text }}{{ " -> " }}{{ form.timeText }}
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div>人數 Person(s):</div>
                  <div class="ml-auto">
                    {{ booking.total_person + " -> " + form.count }}
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding: 1px" :value="3">
              <div class="d-flex flex-column text-center">
                <v-icon size="80" color="#2E7D32">mdi-check-circle</v-icon>
                <div>你的預約已更改</div>
                <div>Your booking has been updated</div>
              </div>
              <div style="" class="mt-4 text-center">
                <div>閣下將會收到確認電郵通知</div>
                <div>An email confirmation will be sent to the given address shortly</div>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <div class="mt-8" v-if="step != 3">
            <v-btn
              v-if="step == 2"
              @click="updateBooking"
              block
              large
              class="mt-2"
              dark
              color="#4E342E"
              style="font-weight: bold"
              >確認更改 Confirm</v-btn
            >
            <v-btn
              block
              large
              v-else
              @click="onEditNextClick"
              color="#4E342E"
              dark
              style="font-weight: bold"
              >下一步 Next</v-btn
            >
            <v-btn
              v-if="step == 0"
              block
              large
              @click="() => (dialog.edit = false)"
              class="mt-2"
              dark
              style="font-weight: bold"
              >返回 BACK</v-btn
            >

            <v-btn
              v-else
              @click="step--"
              block
              large
              class="mt-2"
              dark
              style="font-weight: bold"
            >
              上一步 Previous
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" persistent v-model="dialog.cancel">
      <v-card>
        <v-card-title>取消預約 Cancel Booking</v-card-title>
        <v-card-text
          >是否確定取消預約，一經確定不能再重新預約。如要修改預約請按更改預約。<br />
          Are you sure to cancel the reservation? Once confirmed, you cannot make a new
          reservation. If you want to modify the reservation, please click Edit Booking.
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn dark class="ml-auto" @click="() => (dialog.cancel = false)"
            >返回 BACK</v-btn
          >
          <v-btn color="#4E342E" @click="onConfirmCancel" dark
            >確定 Confirm</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <div class="mx-auto my-auto" style="max-width: 400px; width: 100%">
      <v-card v-if="isCancelSuccess" class="d-flex flex-column">
        <img
          class="mx-auto mt-4"
          style="width: 130px; object-fit: contain"
          :src="require('../assets/welcome_logo.png')"
        />
        <v-card-text class="text-center d-flex flex-column">
          <v-icon size="80" color="#2E7D32">mdi-check-circle</v-icon>
          <div class="mt-4">
            已取消預約<br />
            Booking has been cancelled
          </div>
          <div>參考編號 Reference Number:</div>
          <div>{{ booking.reference_number }}</div>
        </v-card-text>
      </v-card>
      <v-card v-else-if="!isNotFound" class="d-flex flex-column">
        <img
          class="mx-auto mt-4"
          style="width: 130px; object-fit: contain"
          :src="require('../assets/welcome_logo.png')"
        />

        <v-card-text class="d-flex flex-column">
          <div class="text-center" style="font-size: 20px; color: #000000">
            <div>你的預約</div>
            <div>Your reservation</div>
          </div>
          <div class="d-flex mt-4">
            <div>參考編號 Reference Number:</div>
            <div class="ml-auto">{{ booking.reference_number }}</div>
          </div>
          <div class="d-flex mt-4">
            <div>日期 Date:</div>
            <div class="ml-auto">{{ moment(booking.date).format("YYYY-MM-DD") }}</div>
          </div>
          <div class="d-flex mt-4">
            <div>時間 Time:</div>
            <div class="ml-auto">
              {{ booking.timeslot_time_text }}
            </div>
          </div>
          <div class="d-flex mt-4">
            <div>人數 Person(s):</div>
            <div class="ml-auto">{{ booking.total_person }}</div>
          </div>
          <div class="d-flex mt-4">
            <div>姓名 Name:</div>
            <div class="ml-auto">{{ booking.name }}</div>
          </div>
          <div class="d-flex mt-4">
            <div>電郵 Email:</div>
            <div class="ml-auto">{{ booking.email }}</div>
          </div>
          <div class="d-flex mt-4">
            <div>電話 Phone:</div>
            <div class="ml-auto">{{ booking.phone }}</div>
          </div>
          <div class="mt-8">
            <!--    <div8style="width: 49%" class="mr-auto"> -->
            <v-btn
              block
              large
              @click="onEditClick"
              color="#4E342E"
              dark
              style="font-weight: bold"
            >
              更改預約 Edit Booking
            </v-btn>
            <!--   </div>
            <div style="width: 49%"> -->
            <v-btn
              block
              large
              @click="onCancelClick"
              class="mx-auto mt-2"
              dark
              style="font-weight: bold"
              >取消預約 Cancel Booking</v-btn
            >
            <!--  </div> -->
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-text class="text-center">
          <div>找不到預約</div>
          <div>Booking not found</div>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.show">{{ snackbar.text }} </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "EditBooking",
  data() {
    return {
      snackbar: {
        show: false,
        text: "",
      },
      dialog: {
        cancel: false,
        edit: false,
      },
      form: {
        session: 0,
        date: null,
        dateText: "",
        time: null,
        timeText: "",
        count: 1,
      },
      step: 0,
      isLoading: false,
      isNotFound: false,
      isCancelSuccess: false,
      dateOptions: [],
      timeslotOptions: [],
      booking: {
        booking_id: "",
        email: "",
        invoice: "",
        name: "",
        phone: "",
        reference_number: "",
        total_person: "",
        timeslot_time_text: "",
        date: "",
      },
    };
  },
  computed: {
    moment() {
      return moment;
    },
    selectDateList() {
      if (this.form.session === 0) {
        return [];
      } else if (this.form.session === 1) {
        return this.dateOptions
          .filter((d) => {
            return d.session == 1;
          })
          .map((d) => ({
            text: moment(d.date).format("DD MMM YYYY"),
            value: d.timeslot_date_id,
          }));
      } else if (this.form.session === 2) {
        return this.dateOptions
          .filter((d) => {
            return d.session == 2;
          })
          .map((d) => ({
            text: moment(d.date).format("DD MMM YYYY"),
            value: d.timeslot_date_id,
          }));
      }
      return [];
    },
  },
  methods: {
    onTimeSelect(e) {
      if (e.count * 1 + this.form.count * 1 > 18) {
        return;
      }
      this.form.timeText = e.timeslot_time_text;
      this.form.time = e.timeslot_time_id;
    },
    onDateSelect(e) {
      this.form.date = e;
      this.isLoading = true;
      this.form.dateText = this.selectDateList.find((d) => d.value === e).text;
      this.form.time = null;
      axios
        .get(`${process.env.VUE_APP_BACKEND_API}/api/booking/freetimeslot/${e}`)
        .then((response) => {
          this.timeslotOptions = response.data.timeslot;
          console.log(response.data);
          this.isLoading = false;
        });
    },
    fetchDates() {
      this.isLoading = true;
      axios
        .get(`${process.env.VUE_APP_BACKEND_API}/api/timeslot/date`)
        .then((response) => {
          console.log(response.data);
          this.dateOptions = response.data.timeslot_dates;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEditNextClick() {
      if (this.step == 0) {
        if (this.form.session === 0) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇時段 Please select session";

          return;
        } else {
          this.step++;
          this.form.date = null;
          this.form.time = null;
          this.timeslotOptions = [];
        }
      } else if (this.step === 1) {
        if (this.form.date === null) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇日期 Please select date";
          return;
        } else if (this.form.time === null) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇時間 Please select time";
          return;
        } else {
          this.step++;
        }
      }
    },
    onSessionCardClick(e) {
      this.form.session = e;
    },
    onCancelClick() {
      this.dialog.cancel = true;
    },
    onEditClick() {
      this.dialog.edit = true;
      this.fetchDates();
    },
    async onConfirmCancel() {
      this.isLoading = true;
      let res = await axios.post(
        `${process.env.VUE_APP_BACKEND_API}/api/booking/cancel/${this.$route.params.id}`
      );
      if (res.data.status) {
        this.dialog.cancel = false;
        this.isCancelSuccess = true;
      }

      this.isLoading = false;
    },
    async getBooking() {
      try {
        this.isLoading = true;
        let res = await axios.get(
          `${process.env.VUE_APP_BACKEND_API}/api/booking/${this.$route.params.id}`
        );
        this.isLoading = false;
        this.booking = res.data.booking;
      } catch (e) {
        console.log(e);
        this.isNotFound = true;
        this.isLoading = false;
      }
    },
    async updateBooking() {
      let res = await axios.post(
        `${process.env.VUE_APP_BACKEND_API}/api/booking/edit/${this.$route.params.id}`,
        {
          date: this.form.date,
          time: this.form.time,
          count: this.form.count,
        }
      );
      if (res.data.status) {
        this.step = 3;
      }
    },
  },
  created() {
    this.getBooking();
  },
};
</script>
