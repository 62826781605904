<template>
  <div
    class="d-flex welcome-font"
    :style="{
      'background-image': `url('${require('../assets/welcome_background.png')}')`,
    }"
    style="height: 100%; width: 100%; background-size: cover"
  >
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      style="max-width: 740px"
      class="mx-auto my-auto"
    >
      <img
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="800"
        data-aos-offset="0"
        style="width: 150px; object-fit: contain"
        :src="require('../assets/welcome_logo.png')"
      />
      <div class="d-flex">
        <div
          data-aos="fade-right"
          data-aos-delay="1900"
          data-aos-duration="800"
          class="d-flex flex-column px-4 pb-8"
        >
          <div class="my-auto S" style="color: #ffffff; font-size: 20px">
            <div class="welcome-font">
              Welcome to Join Venchi<br />
              9 Levels of Dark Chocolate Bars Blind Tasting Challenge
            </div>
            <div class="mt-4">歡迎參加VENCHI<br />奢華9重黑巧克力品味活動</div>

            <div class="d-flex mt-8" style="font-size: 20px">
              <div class="ml-auto my-auto">18 - 20 OCT</div>
              <div
                class="mx-4"
                style="height: 40px; width: 2px; background-color: #f2f2f2"
              ></div>
              <div class="mr-auto my-auto">25 - 27 OCT</div>
            </div>
            <!--           <div class="text-center mt-8" style="font-size: 30px">11:00 - 20:00</div> -->
            <div class="mt-4 text-center" style="font-size: 12px">
              奕居套房, 奕居, 金鐘道88號<br />
              Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
            </div>
          </div>
          <!--      <img
            class="mt-auto mx-auto"
            style="width: 310px; object-fit: contain"
            :src="require('../assets/cover_text_1.png')"
          />
          <img
            class="my-2 mx-auto"
            style="width: 350px; object-fit: contain"
            :src="require('../assets/cover_text_2.png')"
          />
          <img
            class="mb-auto mx-auto"
            style="width: 250px; object-fit: contain"
            :src="require('../assets/cover_text_3.png')"
          /> -->

          <div data-aos-delay="3000" data-aos-duration="1000">
            <v-btn
              class="mt-4"
              data-aos="fade-up"
              data-aos-delay="2300"
              data-aos-duration="1200"
              x-large
              @click="onButtonClick"
              dark
              color="#4E342E"
              block
            >
              Booking 預約
            </v-btn>
          </div>
        </div>
        <img
          class="ml-auto"
          data-aos="fade-left"
          data-aos-easing="ease-in-back"
          data-aos-delay="700"
          data-aos-duration="800"
          style="
            width: 400px;
            object-fit: contain;
            filter: drop-shadow(3px 1px 3px #000000);
          "
          :src="require('../assets/welcome_chocolate.png')"
        />
      </div>
    </div>
    <div v-else style="width: 100%" class="d-flex my-auto flex-column">
      <img
        class="ml-2 mb-auto"
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="600"
        data-aos-offset="0"
        style="width: 130px; object-fit: contain"
        :src="require('../assets/welcome_logo.png')"
      />
      <div class="d-flex px-4">
        <div
          data-aos="fade-right"
          data-aos-delay="2100"
          data-aos-duration="600"
          data-aos-offset="0"
          class="my-auto"
          style="color: #ffffff; font-size: 18px"
        >
          <div>
            Welcome to Join Venchi<br />
            9 Levels of Dark Chocolate Bars Blind Tasting Challenge
          </div>
          <div class="mt-4">歡迎參加VENCHI<br />奢華9重黑巧克力品味活動</div>
          <div class="d-flex mt-4" style="font-size: 15px">
            <div class="ml-auto my-auto">18 - 20 OCT</div>
            <div
              class="mx-4"
              style="height: 50px; width: 2px; background-color: #f2f2f2"
            ></div>
            <div class="mr-auto my-auto">25 - 27 OCT</div>
          </div>
        </div>
        <img
          data-aos="fade-left"
          data-aos-delay="900"
          data-aos-duration="600"
          data-aos-offset="0"
          style="width: 150px; object-fit: contain"
          class="ml-auto mr-2"
          :src="require('../assets/welcome_chocolate.png')"
        />
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="2100"
        data-aos-duration="600"
        data-aos-offset="0"
        class="my-auto"
        style="color: #ffffff;d"
      >
        <!--     <div class="text-center mt-8" style="font-size: 35px">11:00 - 20:00</div> -->
        <div class="mt-8 text-center" style="font-size: 12px">
          奕居套房, 奕居, 金鐘道88號<br />
          Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
        </div>
      </div>
      <div class="px-4">
        <v-btn
          data-aos="fade-up"
          data-aos-delay="2100"
          data-aos-duration="600"
          x-large
          class="mt-16"
          @click="onButtonClick"
          dark
          color="#4E342E"
          block
        >
          Booking 預約
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "Welcome",
  methods: {
    onButtonClick() {
      this.$router.push("/booking");
    },
    //
  },
  created() {
    AOS.init({ startEvent: "DOMContentLoaded", once: true, duration: 800 });
  },
};
</script>
<style scoped>
.welcome-font {
  font-family: gabriela-stencil, melle-hk !important;
}
.rotate-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin: -200px 0 0 -200px;
  -webkit-animation: spin 6s linear infinite;
  -moz-animation: spin 6s linear infinite;
  animation: spin 6s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
