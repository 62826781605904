<template>
  <v-app>
    <v-main style="background-color: #f7f2e6">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.v-application {
  font-family: brandon-reg, gabriela-stencil !important;

  .title {
    font-family: brandon-reg, gabriela-stencil !important;
  }
}
</style>
